import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import ReviewItem from "./components/ReviewItem";
import ResidentPagination from "./components/Pagination";
import SortOptions from "./components/SortOptions";
import DownIcon from "../../assets/images/ResidentReview/DownIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../../services/axiosConfig";
import useDeviceSize from "../../hooks/useDeviceSize";
import MobileSortOptions from "./components/MobileSortOPT";
import Icon from "../../assets/images/ResidentReview/selectarea.png";
import useCheckItems from "../../store/modal/checkItems";
import ChoiceArea from "../../components/ChoiceArea";
import useChoiceAreaModalState from "../../store/modal/choiceArea";

interface Review {
  id: number;
  title: string;
  thumbnailUrl: string;
  new: boolean;
  scrapped: boolean;
}
interface ItemType {
  id: number;
  name: string;
}

function ResidentReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useDeviceSize();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = Number(queryParams.get("page")) || 1;
  const selectedOption = queryParams.get("sortBy") || "정렬";

  const { open, choiceAreaOpen, close } = useChoiceAreaModalState();
  const { items } = useCheckItems();

  const [reviews, setReviews] = useState<Review[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElement, setTotalElement] = useState<string>('0');
  const [messageState, setMessageState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const options = [
    { label: "최신순", value: "latest" },
    { label: "과거순", value: "oldest" },
    { label: "추천순", value: "mostRecommend" },
    { label: "조회수순", value: "mostViews" },
  ];

  const matchedOption = options.find((e) => e.value === selectedOption);
  const Mlabel = matchedOption?.label || "정렬";

  useEffect(() => {
    const fetchReviews = async () => {
      const ids = items.map((value) => value.id);
      try {
        const response = await axiosInstance.get("/review/list", {
          params: {
            page: currentPage,
            districtId: ids.join(","),
            sortBy: selectedOption,
          },
        });
        const data = response.data.content;
        setReviews(data);
        setTotalPages(response.data.totalPages);
        setTotalElement(response.data.totalElements.toLocaleString());
      } catch (error) {}
    };

    fetchReviews();
  }, [currentPage, selectedOption, items.length]);

  const updateQueryParams = (page: number, sortBy: string) => {
    const queryParams = new URLSearchParams();
    queryParams.set("page", String(page));
    queryParams.set("sortBy", sortBy);

    navigate(`?${queryParams.toString()}`, { replace: true });
  };

  const handlePageChange = (page: number) => {
    updateQueryParams(page, selectedOption);
  };

  const handleSortSelect = (option: string) => {
    updateQueryParams(1, option);
  };

  const handleDetailClick = (id: number) => {
    navigate(`/residentreview/detail/${id}`);
  };

  const handleOffMessage = () => {
    setMessageState(!messageState);
  };

  return (
    <ResidentReviewWrapper>
      {messageState && (
        <AlertMessage>
          <div
            onClick={handleOffMessage}
            style={{
              position: "fixed",
              right: "10px",
              top: "5px",
              cursor: "pointer",
            }}
          >
            x
          </div>
          스크랩했습니다.
        </AlertMessage>
      )}
      <ResponsiveWrapper>
        <HeaderContainer>
          <ReviewHeader>입주후기</ReviewHeader>
          {choiceAreaOpen && <ChoiceArea />}
          {!isMobile ? (
            <SlectArea
              onClick={() => {
                updateQueryParams(1, selectedOption);
                open("location");
              }}
            >
              지역선택{" "}
              <img
                style={{ width: "15px", height: "8px" }}
                src={DownIcon}
                alt="Down Icon"
              />
            </SlectArea>
          ) : (
            <Div>
              <img
                style={{ width: "32px", height: "23px" }}
                onClick={() => {
                  updateQueryParams(1, selectedOption);
                  open("location");
                }}
                src={Icon}
              />
              <MobileSortBTN onClick={() => setIsModalOpen(true)}>
                {Mlabel}
                <img
                  src={DownIcon}
                  style={{
                    width: "9px",
                    height: "5px",
                    paddingLeft: "5px",
                  }}
                ></img>
              </MobileSortBTN>
              {isModalOpen && (
                <ModalOverlay onClick={() => setIsModalOpen(false)}>
                  <ModalContent onClick={(e) => e.stopPropagation()}>
                    <MobileSortOptions onSelect={handleSortSelect} />
                  </ModalContent>
                </ModalOverlay>
              )}
            </Div>
          )}
        </HeaderContainer>
      </ResponsiveWrapper>

      <ResidentReviewContents>
        <ResponsiveWrapper>
          <HeaderContainer>
            <div>전체 {totalElement}</div>
            {!isMobile && <SortOptions onSelect={handleSortSelect} />}
          </HeaderContainer>
          <ItemWrapper>
            {reviews.map((review, key) => (
              <div
                style={{ cursor: "pointer" }}
                key={key}
                onClick={() => handleDetailClick(review.id)}
              >
                <ReviewItem
                  images={review.thumbnailUrl}
                  title={review.title}
                  isNew={review.new}
                  isScrapped={review.scrapped}
                  id={review.id}
                  setMessageState={setMessageState}
                />
              </div>
            ))}
          </ItemWrapper>
        </ResponsiveWrapper>
      </ResidentReviewContents>
      <ResidentPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <ResidentReviewMargin />
    </ResidentReviewWrapper>
  );
}

export default ResidentReview;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 58px;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 25px;
    margin-bottom: 10px;
  }
`;

const SlectArea = styled.button`
  border: 1px solid #8b8b8b;
  background-color: white;
  border-radius: 8px;
  height: 30px;
  font-weight: 600;
  @media screen and (max-width: 1023px) {
    font-size: 11px;
  }
`;

const ReviewHeader = styled.div`
  font-size: 38px;
  font-weight: bold;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 0;
    font-size: 20px;
  }
`;

const ResidentReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResidentReviewMargin = styled.div`
  width: 100%;
  height: 0;
  @media screen and (min-width: 1280px) {
    margin-bottom: 51px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 54px;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 26px;
  }
`;

const ResidentReviewContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 1280px) {
    gap: 58px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 67px;
  }
  @media screen and (max-width: 1023px) {
    gap: 35px;
  }
`;

const ItemWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 20px;
  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 26px;
  }
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const AlertMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 476px;
  height: 136px;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(187, 185, 185, 0.5);
  background-color: #efefef;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 5000;
  @media screen and (max-width: 1023px) {
    width: 385px;
    height: 133px;
    font-size: 18px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2000;
`;

const ModalContent = styled.div`
  z-index: 2200;
  width: 100%;
  height: 286px;
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s forwards;
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const MobileSortBTN = styled.button`
  padding: 5px;
  color: black;
  background-color: #f5f7f8;
  border-radius: 8px;
  border: none;
  min-width: 58px;
  width: fit-content;
  height: 28px;
  text-align: center;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Div = styled.div`
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;
`;
