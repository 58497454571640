import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import OffScrap from "../../assets/images/mypage/Shape 3 copy 2.png";
import { useNavigate } from "react-router-dom";
import { useScrapCount } from "../../context/ScrapCountContext";
import axiosInstance from "../../services/axiosConfig";

// 공통 스타일
const commonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eee;
`;

const ContentContainer = styled.div`
  ${commonStyles}
  flex-direction: column;
  height: 100%;
  color: #ccc;
  font-size: 23px;
  padding: 0 20px;
  background-color: #ffffff;
  margin-bottom: 100px;
`;

const NoScrap = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin-top: 150px;
  margin-bottom: 290px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;
  margin-bottom: 16px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    grid-template-columns: repeat(3, 297px);
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
  }
`;

const GridItem = styled.div<{ thumbnail: string }>`
  width: 270px;
  height: 270px;
  background-color: #eee;
  border-radius: 4px;
  background-image: url("${(props) => props.thumbnail}");
  background-size: cover;
  background-position: center;
  position: relative;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 297px;
    height: 297px;
  }

  @media screen and (max-width: 1023px) {
    width: 122px;
    height: 122px;
  }
`;


const OverlayImage = styled.img`
  position: absolute;
  bottom: 10px; /* 하단에서 10px 위로 */
  right: 10px; /* 오른쪽에서 10px 왼쪽으로 */
  width: 25px; /* 이미지의 너비 */
  height: 25px; /* 이미지의 높이 */

  @media screen and (max-width: 1023px) {
    width: 19px;
    height: 19px;
  }
`;

const Button = styled.button`
  height: 54px;
  padding: 12px 35px;
  margin: 98px;
  background-color: #99393a;
  color: white;
  font-size: 23px;
  font-weight: bold;
  border: none;
  border-radius: 27px;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 11px 20px;
    margin: 20px;
    border-radius: 5px;
    box-sizing: border-box;
  }
`;

// 공통 스타일 생략

const All: React.FC = () => {
  const [visibleItems, setVisibleItems] = useState<any[]>([]);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth <= 1023
  );

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(isSmallScreen ? 9 : 12);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setCounts, counts } = useScrapCount();

  const fetchItems = async (page: number, limit: number) => {
    try {
      const response = await axiosInstance.get("/mypage/all", {
        params: { page, limit },
      });
      // console.log("받아오는 값:", response.data);

      if (response.data.length < limit) {
        setHasMoreItems(false);
      } else if (counts.all == visibleItems.length) {
        setHasMoreItems(false);
      } else {
        setHasMoreItems(true);
      }

      // setCounts(prev => ({ ...prev, all: response.data.length }));
      return response.data;
    } catch (error) {
      // console.error("Failed to fetch items:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchInitialItems = async () => {
      const initialItems = await fetchItems(0, limit);
      setVisibleItems(initialItems);
      if (initialItems.length < limit) {
        setHasMoreItems(false);
      }
    };

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1023);
      setLimit(window.innerWidth <= 1023 ? 9 : 12);
    };

    window.addEventListener("resize", handleResize);
    fetchInitialItems();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen, limit]);

  const loadMore = async () => {
    const newOffset = offset + 1;
    const newItems = await fetchItems(newOffset, limit);
    setVisibleItems((prevVisibleItems) => [...prevVisibleItems, ...newItems]);
    setOffset(newOffset);
    if (newItems.length < limit) {
      setHasMoreItems(false);
    }
  };

  const handleOverlayClick = async (
    type: string,
    id: number,
    scrapId: number
  ) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (confirmDelete) {
      try {
        const response = await axiosInstance.post(
          `/normal/user/scrap`,
          {
            category: type,
            entityId: id,
          }
        );
  
        // 삭제된 아이템을 필터링하여 상태를 업데이트
        setVisibleItems((prevVisibleItems) =>
          prevVisibleItems.filter((item) =>
            type === "review" ? item.reviewId !== id : item.roomId !== id
          )
        );
  
        // 카운트를 업데이트
        setCounts((prev) => ({
          ...prev,
          all: prev.all - 1,
          review: type === "review" ? prev.review - 1 : prev.review,
          room: type === "room" ? prev.room - 1 : prev.room,
        }));
      } catch (error) {
        console.error("Failed to delete item:", error);
      }
    }
  };
  

  const renderGridItems = () =>
    visibleItems.map((item) => (
      <GridItem
        key={item.scrapId}
        thumbnail={item.thumbnailUrl}
        onClick={() => handleItemClick(item)}
      >
        <OverlayImage
          src={OffScrap}
          alt="Overlay Image"
          onClick={(e) => {
            e.stopPropagation();
            handleOverlayClick(
              item.type,
              item.reviewId || item.roomId,
              item.scrapId
            );
          }}
        />
      </GridItem>
    ));

    const handleItemClick = (item: any) => {
      if (item.type === "room") {
        navigate(`/detail?roomId=${item.roomId}`);
      } else if (item.type === "review") {
        navigate(`/residentreview/detail/${item.reviewId}`);
      }
    };
    
  // const renderGridItems = () =>
  //   visibleItems.map((item) => (
  //     <GridItem
  //       key={item.scrapId}
  //       thumbnail={item.thumbnailUrl}
  //       onClick={() => handleItemClick(item)}
  //     >
  //       <OverlayImage
  //         src={OffScrap}
  //         alt="Overlay Image"
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           handleOverlayClick(item.scrapId);
  //         }}
  //       />
  //     </GridItem>
  //   ));

  return (
    <ContentContainer>
      {visibleItems.length === 0 ? (
        <NoScrap>저장하신 스크랩이 없습니다</NoScrap>
      ) : (
        <>
          <GridContainer>{renderGridItems()}</GridContainer>
          {hasMoreItems && <Button onClick={loadMore}>더보기</Button>}
        </>
      )}
    </ContentContainer>
  );
};

export default All;
