import styled from "styled-components";
import InformationBox from "./InformationBox";
import { roomDetailType } from "../Detail";
import checkIcon from "../../../assets/images/detail/checkIcon.png";
import pointIcon from "../../../assets/images/detail/pointIcon.png";

function DetailPoint({
  roomDetailState,
}: {
  roomDetailState?: roomDetailType;
}) {
  return (
    <DetailLocationWrapper>
      <InformationBox
        width={{ pc: "100%", laptop: "100%", mobile: "100%" }}
        height={{ pc: "178px", laptop: "166.5px", mobile: "176px" }}
      >
        <PointInner>
          <PointTop>
            <PointIcon src={pointIcon} />
            <PointTitle>이 매물의 추천 포인트!</PointTitle>
          </PointTop>
          <PointItemDiv>
            {roomDetailState?.recommendPoints.map((item, index) => (
              <PointItem key={index}>
                <CheckIcon src={checkIcon} />
                <PointExplain>{item}</PointExplain>
              </PointItem>
            ))}
          </PointItemDiv>
        </PointInner>
      </InformationBox>
    </DetailLocationWrapper>
  );
}

export default DetailPoint;

const DetailLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #f7f9fa;
  @media screen and (min-width: 1280px) {
    margin-top: 55px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 52px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 32.5px;
  }
`;

const PointInner = styled.div`
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1280px) {
    padding: 0 43px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 39px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 15px;
  }
`;

const PointTop = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    gap: 3px;
    margin-top: 24px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 4px;
    margin-top: 22px;
  }
  @media screen and (max-width: 1023px) {
    gap: 4px;
    margin-top: 17px;
  }
`;

const PointIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 17px;
    height: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 16px;
    height: 25px;
  }
  @media screen and (max-width: 1023px) {
    width: 17px;
    height: 26px;
  }
`;

const PointTitle = styled.div`
  font-weight: 600;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`;

const PointItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2px;
  @media screen and (min-width: 1280px) {
    margin-top: 21px;
    gap: 18px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 21px;
    gap: 18px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 22px;
    gap: 19px;
  }
`;

const PointItem = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    gap: 6px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 7px;
  }
  @media screen and (max-width: 1023px) {
    gap: 5px;
  }
`;

const CheckIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 18px;
    height: 15px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 17px;
    height: 13px;
  }
  @media screen and (max-width: 1023px) {
    width: 18px;
    height: 15px;
  }
`;

const PointExplain = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;
